<template>
  <div>
    <div class="property">
      <div class="label">
        {{ $t('interface_editor.component_editor.table.source_type') }}
      </div>
      <el-select
        size="mini"
        :value="extensions.source_type"
        @input="$emit('change-extension', {name: 'source_type', value: $event})"
        :placeholder="$t('interface_editor.component_editor.table.source_type')">
        <el-option
          v-for="item in ['query', 'extended_object']"
          :key="item"
          :label="$t('interface_editor.component_editor.editor_tasks_source.source.' + item)"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <div class="property" v-if="extensions.source_type">
      <div class="label">
        {{ $t('interface_editor.component_editor.editor_tasks_source.source.' + extensions.source_type) }}
      </div>
      <el-select
        :value="parseInt(extensions.source_id) || null"
        filterable
        size="mini"
        @input="$emit('change-extension', {name: 'source_id', value: $event})"
      >
        <el-option
          v-for="(item, index) in sources"
          :key="index"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </div>
    <variables-block
      :title="$t('process_editor.element_properties.service_task.input_variables')"
      :extensions="extensions"
      name="input_variables"
      @change-extension="$emit('change-extension', $event)"
      @remove-extension="$emit('remove-extension', $event)"
    />
    <variables-block
      :title="$t('process_editor.element_properties.user_task.returning_variables')"
      :extensions="extensions"
      hide-type
      name="returning_variables"
      @change-extension="$emit('change-extension', $event)"
      @remove-extension="$emit('remove-extension', $event)"
    />
  </div>
</template>

<script>
import mixins from '../../mixins'
import VariablesBlock
  from '@/services/BPMNEditor/infrastructure/components/PropertiesPanel/Shared/VariablesBlock/index.vue'

export default {
  name: 'GetVariables',
  components: { VariablesBlock },
  mixins: [mixins],
  data () {
    return {
      sources: []
    }
  },
  watch: {
    'extensions.source_type': {
      async handler (val) {
        this.sources = await this.getData(val)
      },
      immediate: true
    }
  },
  methods: {
    async getData (type) {
      let data = []
      switch (type) {
        case 'extended_object':
          data = await this.$http.get(`${this.$config.api}/bieditor/extended_objects`)
          break
        case 'query':
          data = await this.$http.get(`${this.$config.api}/bieditor/queries`)
          break
      }

      return data.data
    }
  }
}
</script>
